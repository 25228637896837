import React from "react";
import Link from "next/link";
import GrayLogo from "../../../../assets/svgs/logoGray.svg";
import LinkedIn from "../../../../assets/svgs/linkedin.svg";
import Facebook from "../../../../assets/svgs/facebook.svg";
import Tweeter from "../../../../assets/svgs/tweeter.svg";
import Redit from "../../../../assets/svgs/redit.svg";
import Youtube from "../../../../assets/svgs/youtube.svg";
import Telegram from "../../../../assets/svgs/telegram.svg";
import Medium from "../../../../assets/svgs/medium.svg";
import Instagram from "../../../../assets/svgs/instagram.svg";
import { useTranslation } from "next-i18next";

const Footer = () => {
  const { t } = useTranslation("common");
  return (
    <footer className="footer mt-6 md:mt-28">
      <div className="container">
        <div className="topRow row pb-6">
          <div className="col-12 md:col-3">
            {/* <Link href="/pages">
              <GrayLogo className={'h-auto w-[104px]'} />
            </Link> */}
            <Link href="/">
              <GrayLogo className={"h-auto w-[104px]"} />
            </Link>
            {/* <p className="mt-4 mb-8 pr-4 text-base text-gray4">
              {t('candaoInfo')}{' '}
            </p> */}
            <p className="mb-2 mt-4 pr-4 text-base text-gray4">
              Candao is a place that monetizes every second of your life
            </p>
            <p className="text-[13px] mb-1 text-gray-600">
              <div className="font-medium">Candao Information Technology LLC</div>
              <div>Al-Khabeesi Building, Dubai, UAE</div>
            </p>
          </div>
          <div className="col-6 md:col-3">
            {/* <div className="flex flex-col items-start">
              <a
                href="https://gitbook.candao.io/whitepaper/"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                {t('whitePaper')}
              </a>
              <Link
                href="/media-and-news"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                {t('mediaAndNews')}
              </Link>
              <Link
                href="/press-kit"
                className="md:mb-0text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                {t('pressKit')}
              </Link>
            </div> */}
            <div className="flex flex-col items-start">
              <a
                href="https://gitbook.candao.io/whitepaper/"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                Whitepaper
              </a>
              <Link
                href="/media-and-news"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                Media and news
              </Link>
              <Link
                href="/press-kit"
                className="md:mb-0text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                Candao press kit
              </Link>
            </div>
          </div>
          <div className="col-6 md:col-3">
            {/* <div className="flex flex-col items-start">
              <a
                href="#join-as"
                className="text-rubik block  mb-2 text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                {t('joinAs')}
              </a>
              <a
                href="https://gitbook.candao.io/whitepaper/cdo-allocation-and-distribution-schedule"
                target="_blank"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
                rel="noreferrer"
              >
                {t('tokenomics')}
              </a>
              <a
                href="https://gitbook.candao.io/whitepaper/the-breakdown-of-candao-products."
                target="_blank"
                className="text-rubik block text-gray3 transition-all duration-300 hover:text-electricViolet"
                rel="noreferrer"
              >
                {t('roadmap')}
              </a>
            </div> */}
            <div className="flex flex-col items-start">
              <a
                href="https://gitbook.candao.io/whitepaper/cdo-allocation-and-distribution-schedule"
                target="_blank"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
                rel="noreferrer"
              >
                Tokenomics
              </a>
              <a
                href="https://gitbook.candao.io/whitepaper/8.-product-development-and-roadmap"
                target="_blank"
                className="text-rubik block text-gray3 transition-all duration-300 hover:text-electricViolet"
                rel="noreferrer"
              >
                Roadmap
              </a>
            </div>
          </div>
          <div className="col-6 md:col-3">
            {/* <div className="flex flex-col items-start">
              <a
                href="/documents/Candao_Web_Terms_and_Conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                {t('termsAndConditions')}
              </a>
              <a
                href="/documents/Candao_Web_Privacy_Policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                {t('privacyPolicy')}
              </a>
              <div className="pt-2"></div>
            </div> */}
            <div className="flex flex-col items-start">
              <a
                href="/documents/Candao_Web_Terms_and_Conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                Terms and Conditions
              </a>
              <a
                href="/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-rubik mb-2 block text-gray3 transition-all duration-300 hover:text-electricViolet"
              >
                Privacy Policy
              </a>
              <div className="pt-2"></div>
            </div>
          </div>
        </div>
        <div className="bottomRow row border-t py-6">
          <div className="col-12">
            <div className="flex flex-col-reverse items-center justify-center md:flex-row">
              {/* <p className="text-caption text-gray4">{t('copyrights')}</p> */}
              <p className="text-caption text-gray4">
                Candao © 2024 All rights reserved
              </p>
              <div className="socialLinks">
                {/* <a
                  href="https://www.linkedin.com/company/79693848"
                  className="socialLink"
                >
                  <LinkedIn />
                </a> */}
                <a href="https://twitter.com/Candao_io" className="socialLink">
                  <Tweeter />
                </a>
                <a
                  href="https://www.instagram.com/candao_protocol"
                  className="socialLink"
                >
                  <Instagram />
                </a>
                <a
                  href="https://www.facebook.com/candao.io"
                  className="socialLink"
                >
                  <Facebook />
                </a>
                <a
                  href="https://www.reddit.com/r/candao_io"
                  className="socialLink redit"
                >
                  <Redit />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCUAVyvzioNh4EMSn0EKl5ug"
                  className="socialLink"
                >
                  <Youtube />
                </a>
                <a href="https://t.me/candao_protocol" className="socialLink">
                  <Telegram />
                </a>
                <a href="https://medium.com/@candao.io" className="socialLink">
                  <Medium />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
